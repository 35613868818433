<!-- 配送费设置 -->

<template>
  <div class="feeSetting">
    <div class="globle_border" v-loading="loading">
      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="setType" label="费用类型">
            <template slot-scope="scope">
              <div>
                {{ getFeeTypeText(scope.row.setType) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column min-width="150" show-overflow-tooltip prop="feeAmount" label="金额(元)"></el-table-column>
          <el-table-column min-width="150" label="状态" header-align="center" align="center">
            <template slot-scope="scope" v-if="scope.row.setType != 4">
              <el-switch v-model="scope.row.switch" active-color="#069BBC" inactive-color="#E2E2E2"
                @change="changeStatus(scope.row)"></el-switch>
            </template>
          </el-table-column>
          <el-table-column label="操作" header-align="center" align="center" width="180" fixed="right">
            <template slot-scope="scope">
              <el-button size="small" class="btn" type="primary" plain @click="handleModify(scope.row)">修改</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>

    </div>

    <!-- 分页 -->
    <!-- <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr" layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination> -->

    <!-- 弹窗 -->
    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" @close="handleCloseDialog">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-row class="row">
          <el-col :span="24">
            <el-form-item label="金额(元)：" prop="fee">
              <el-input v-model="ruleForm.fee" placeholder="请输入"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="handleCloseDialog" type="primary" plain>取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      id: null,
      // 表格数据
      tableData: [],
      // 弹窗数据
      dialogVisible: false, // 弹窗显隐
      dialogTitle: '', // 弹窗标题
      ruleForm: {
        fee: '',
      },
      rules: {
        fee: [{ required: true, message: '请输入', trigger: 'blur' }, { pattern: /^[1-9]\d*$/, message: '不能输入数字以外的字符', trigger: 'blur' }]
      },
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    }
  },
  created() {
    this.getShopFeeSetList();
  },
  methods: {
    // 获取商品列表
    getShopFeeSetList() {
      this.loading = true;
      this.$axios.get(`${this.$api.getShopFeeSetList}/${this.$store.state.userInfo.shopId}`).then((res) => {
        if (res.data.code == 100) {
          let list = res.data.result.map((e) => {
            return {
              ...e,
              switch: e.status == "1" ? true : false,
            };
          });
          this.tableData = list;
          this.totalItemsCount = res.data.result.totalCount;
        }
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      })
    },
    // 修改起送价
    handleModify(row) {
      this.dialogVisible = true;
      this.id = row.id;
      this.$set(this.ruleForm, 'fee', row.feeAmount)
      switch (String(row.setType)) {
        case '1':
          return this.dialogTitle = '修改运费';
        case '2':
          return this.dialogTitle = '修改店铺起送价';
        case '3':
          return this.dialogTitle = '修改打包费';
        case '4':
          return this.dialogTitle = '修改骑手配送费';
        default:
          return '';
      }
    },
    // 关闭弹窗
    handleCloseDialog() {
      this.dialogVisible = false;
      this.dialogTitle = '';
      this.resetForm();
    },
    // 表单校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) {
          console.log('error submit!!');
          return false;
        } else {
          this.$axios.post(this.$api.updateShopFeeAmount, {
            id: this.id,
            shopId: this.$store.state.userInfo.shopId,
            feeAmount: this.ruleForm.fee,
          }).then((res) => {
            if (res.data.code == 100) {
              this.$message.success('修改成功!');
              this.handleCloseDialog();
              this.getShopFeeSetList();
            }
          })
        }
        this.handleCloseDialog();
      });
    },
    // 重置表单
    resetForm() {
      this.ruleForm = {};
      this.$refs.ruleForm.resetFields();
    },
    // 切换上下架
    changeStatus(row) {
      const originalSwitch = row.switch;
      this.$confirm(`确定${row.switch ? "启用" : "禁用"}吗？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        center: true,
      }).then(() => {
        this.$axios.post(this.$api.updateShopFeeStatus, {
          id: row.id,
          shopId: this.$store.state.userInfo.shopId,
          status: row.switch ? "1" : "0",
        }).then(() => {
          this.$set(row, "switch", originalSwitch);
          this.$message.success("操作成功");
          this.getShopFeeSetList()
        }).catch(() => {
          this.$set(row, "switch", !originalSwitch);
          this.$message.error("操作失败");
          this.getShopFeeSetList()
        });
      }).catch(() => {
        this.$set(row, "switch", !originalSwitch);
        this.getShopFeeSetList()
      });
    },
    // 费用类型
    getFeeTypeText(setType) {
      switch (String(setType)) {
        case '1':
          return '运费';
        case '2':
          return '店铺起送价';
        case '3':
          return '打包费';
        case '4':
          return '骑手配送费';
        default:
          return '';
      }
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 下拉选择
    handleSelect(value) {
      this.selectValue = value;
      this.onSearch();
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getShopFeeSetList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.pageSize = e;
      this.onSearch();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getShopFeeSetList();
    },

  }

}



</script>

<style scoped lang="scss">
.feeSetting {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
    }

    .el-input,
    .el-select,
    .el-button {
      margin-left: 20px;
    }

  }
}
</style>
